import router from './router'
import Store from '@/store'
import { recursive } from '@/plugins/methods'
import { Message } from 'element-ui'

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.matched.length) {
    // 路由白名单
    const whiteList = ['login', 'modifyEmail', 'modifyPassword', 'personnalInfo', '404', 'upgrade']

    // 是否验证用户
    const isAuthenticatedAccount = !!Store.state.account.loginInfo.userName

    if (whiteList.includes(to.name)) next()
    else {
      if (!isAuthenticatedAccount) next({ name: 'login' })
      else if (!isAuthenticatedRoute(to)) next({ name: '404' })
      else next()
    }
  } else {
    Message.error('Invalid route ')
    console.log('Invalid route',to)
    next({ path: from.fullPath })
  }
})

// 是否验证路由
const isAuthenticatedRoute = function(to) {
  const asyncRouterMap = Store.state.account.asyncRouterMap
  let isIncluded = false
  recursive(asyncRouterMap, (item) => {
    if (item.rsUrl.search(to.meta.power) != -1) isIncluded = true
  })
  return isIncluded
}
