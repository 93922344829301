<style lang="scss" scoped>
.footer {
  padding: 0 30px;
  height: 44px;
  line-height: 44px;
  color: #adadad;
  font-size: 14px;
  text-align: center;
  background-color: #dddddd;
  .tools {
    float: right;
  }
}
.dropdown {
  margin-left: 20px;
  .el-dropdown-link {
    color: #898989;
    font-size: 14px;
    .el-icon-arrow-down {
      font-size: 18px;
      vertical-align: middle;
    }
  }
}
.project-dropdown {
  height: 300px;
  overflow: auto;
  .el-dropdown-menu__item.isActive {
    color: $--color-primary;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="footer">
    <span>{{ $t('copyright') }}</span>
    <span class="tools">
      <span class="text-primary">{{ $t('cookies') }}</span>
      <el-dropdown
        size="small"
        @command="handleCommand"
        trigger="click"
        placement="top-end"
        class="dropdown"
      >
        <span class="el-dropdown-link">
          {{ timezoneList[zoneId] && timezoneList[zoneId].description }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="project-dropdown">
            <el-dropdown-item
              v-for="(item, key) in timezoneList"
              :key="key"
              :command="item.id"
              :class="{ isActive: item.id == zoneId }"
              >{{ item.description }}
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTimeZoneListApi } from '@/api/app'

export default {
  name: 'layoutFooter',
  data() {
    return {
      timezoneList: [],
    }
  },
  computed: {
    ...mapState('account', ['loginInfo']),
    zoneId: {
      get() {
        //console.log("zoneId:", this.loginInfo.zoneId)
        if (this.timezoneList[this.loginInfo.zoneId]) {
          this.$store.commit(
            'account/SET_ZONE_OFFSET',
            this.timezoneList[this.loginInfo.zoneId].offset
          )
        }
        return this.loginInfo.zoneId
      },
      set(val) {
        this.$store.commit('account/SET_ZONE_ZONEID', this.timezoneList[val])
      },
    },
  },
  created() {
    this.getTimeZoneList()
  },
  methods: {
    // 时区
    getTimeZoneList() {
      getTimeZoneListApi()
        .then(({ data }) => {
          let obj = {}
          data.result.rows.forEach((e) => {
            if (e) obj[e.id] = e
          })
          this.timezoneList = obj
        })
        .catch(() => {})
    },
    handleCommand(zoneId) {
      this.zoneId = zoneId
    },
  },
}
</script>
