export default [
  {
    path: '/device',
    name: 'device',
    meta: {
      power: 'device',
    },
    component: () => import(/* webpackChunkName: "Device" */ '@/views/Device/Device.vue'),
  },
  {
    path: '/device/addDevice',
    name: 'addDevice',
    meta: {
      power: 'device',
    },
    component: () =>
      import(/* webpackChunkName: "DeviceDetails" */ '@/views/Device/DeviceDetails.vue'),
  },
  {
    path: '/device/editDevice',
    name: 'editDevice',
    meta: {
      power: 'device',
    },
    component: () =>
      import(/* webpackChunkName: "DeviceDetails" */ '@/views/Device/DeviceDetails.vue'),
  },
  {
    path: '/device/deviceAccount',
    name: 'deviceAccount',
    meta: {
      power: 'device',
    },
    component: () =>
      import(/* webpackChunkName: "DeviceAccount" */ '@/views/Device/DeviceAccount.vue'),
  },
  {
    path: '/device/deviceDetailInfo',
    name: 'deviceDetailInfo',
    meta: {
      power: 'device',
    },
    component: () =>
      import(
        /* webpackChunkName: "DeviceDetailInfo" */ '@/views/Device/DeviceDetailInfo/DeviceDetailInfo.vue'
      ),
  },
  {
    path: '/device/deviceOperationLog',
    name: 'deviceOperationLog',
    meta: {
      power: 'device',
    },
    component: () => import(/* webpackChunkName: "Syslog" */ '@/views/System/Syslog.vue'),
  },
  // 扫描
  {
    path: '/device/scanDevice',
    name: 'scanDevice',
    meta: {
      power: 'device',
    },
    component: () => import(/* webpackChunkName: "ScanDevice" */ '@/views/Device/ScanDevice.vue'),
  },
  {
    path: '/deviceManagement',
    name: 'deviceManagement',
    meta: {
      power: 'deviceManagement',
    },
    component: () => import(/* webpackChunkName: "Device" */ '@/views/Device/Device.vue'),
  },
  {
    path: '/device/deviceAccountAssign',
    name: 'deviceAccountAssign',
    meta: {
      power: 'device',
    },
    component: () =>
      import(
        /* webpackChunkName: "DeviceDetailInfo" */ '@/views/Device/DeviceAccountAssign.vue'
      ),
  },
]
