<template>
  <div class="page-main">
    <div style="height: 100%">
      <div class="page-header">
        <el-button
          type="primary"
          size="small"
          @click="textEditShowChange"
        >{{ textEditShow ? $t('sitParam2.switchToGUIEditor') : $t('sitParam2.switchToTextEditor') }}</el-button>
      </div>
      <!-- 控件配置 -->
      <el-tabs
        v-if="!textEditShow"
        v-loading="loading"
        v-model="tabActive"
        stretch
      >
        <el-tab-pane
          v-for="(item, key) in parData"
          :key="key"
          :name="key"
          lazy
        >
          <el-badge
            slot="label"
            :value="total[key]"
            :hidden="!total[key]"
            type="primary"
          >{{ $t(`sitParam.${key}`) }}</el-badge>
          <SpTab
            v-model="parData[key]"
            :tabName="key"
            :relationId="relationId"
            :relationName="relationName"
            @changeTotal="changeTotal"
            @submit="submit"
            @onlysave="onlysave"
            @cancel="cancel"
          ></SpTab>
        </el-tab-pane>
      </el-tabs>
      <!-- xml配置 -->
      <TEXTEDIT
        v-if="textEditShow"
        ref="textEdit"
        v-model="extent"
        @submit="pushDevice"
        @onlysave="onlysave"
        @cancel="cancel"            
      ></TEXTEDIT>
    </div>
    <!-- 设备推送 -->
    <dialog-task-execution-mode
      v-if="pushDeviceVisible"
      :visible.sync="pushDeviceVisible"
      @submit="taskExecutionModeSubmit"
    ></dialog-task-execution-mode>
  </div>
</template>

<script>
import parameters from './plugins/parameters'
import SpTab from './Layout/sp-tab'
import TEXTEDIT from './TextEdit'
import methods from './plugins/methods'
import { DialogTaskExecutionMode } from '@/components'
import {
  configPushRecord,
  dynamicParameterJs,
  getParam,
  saveParam,
  pushDeviceConfig,
  pushConfigModel,
  pushConfigGroup,
  pushConfigSite,
  pushConfigModelSchedule,
  pushConfigGroupSchedule,
  pushConfigSiteSchedule,
} from '@/api/setParam.js'
import { pushSuccessMassage } from '@/plugins/methods'
import { removeModel } from '@/api/model'
import { removeGroup } from '@/api/group'
import { removeSite } from '@/api/site'

export default {
  name: 'set-param',
  components: {
    SpTab,
    TEXTEDIT,
    DialogTaskExecutionMode,
  },
  props: {
    pushStatus: String,
    relationId: String,
    relationName: String,
  },
  data() {
    return {
      textEditShow: false,       // 显示：T-extent F-setting
      parData: {},               // 配置数据
      backupData: {},            // 配置数据备份
      extent: '',                // xml数据
      backupExtent: '',          // xml备份
      total: {},                 // 高亮参数个数
      pushDeviceVisible: false,  // 显示推送弹窗
      loading: false,            // 显示loading
      tabActive: 'account',      // 当前分页
      // 分页列表
      tabList: [
        'maintenance',
        'directoryHistory',
        'function',
        'settings',
        'phoneKeys',
        'provision',
        'network',
        'sipFunction',
        'account',
      ],
      phoneKeyPushList: new Set(),  // 话机按钮勾选列表（用于计数）
    }
  },
  computed: {
    // 检查数据变化
    hasDataChange() {
      return this.textEditShow
        ? this.backupExtent === this.extent
        : JSON.stringify(this.backupData) === JSON.stringify(this.parData)
    },
    // 提交xml（自动补全）
    submitExtent() {
      return (extent) => {
        let model = this.$route.query.deviceModel
        if (model == "H2" || model == "H2P") {
          return extent
        }
        else {
          let extentList = this.parseExtent(extent).map(extentItem => `<setting id="${extentItem[1]}" value="${extentItem[2]}" override="true"/>`)
          return `<?xml version="1.0" encoding="UTF-8"?>\n<settings>\n\r${extentList.join('\n\r')}\n\r</settings>`
        }
      }
    },
    // 提交数据（设置 => 后端格式）
    submitData() {
      // 初始化提交数据
      const submitForm = {
        account: Object.keys(this.parData.account).map((item, index) => {
          return {
            relationId: this.relationId,
            relationName: this.relationName,
            advanced: '{}',
            basic: '{}',
            codec: '{}',
            no: index + 1,
          }
        }),
        directoryHistory: {
          relationId: this.relationId,
          relationName: this.relationName,
          history: '{}',
          ldap: '{}',
          localDirectory: '{}',
          remotePhoneBook: '{}',
        },
        extent: {
          extent: this.submitExtent(this.extent),
          relationId: this.relationId,
          relationName: this.relationName,
        },
        function: {
          relationId: this.relationId,
          relationName: this.relationName,
          actionUrl: '{}',
          callpickupCallpark: '{}',
          dnd: '{}',
          forward: '{}',
          hotline: '{}',
          intercom: '{}',
          multicastPaging: '{}',
          reomteContal: '{}',
          transfer: '{}',
        },
        maintenance: {
          relationId: this.relationId,
          relationName: this.relationName,
          binaryUpdate: '{}',
          certificateManagement: '{}',
          changePassword: '{}',
          configfileImportExport: '{}',
          logCollection: '{}',
          restoreFactory: '{}',
          security: '{}',
        },
        network: {
          relationId: this.relationId,
          relationName: this.relationName,
          ipParameter: '{}',
          ipsecVpn: '{}',
          lldp: '{}',
          natVpn: '{}',
          openVpn: '{}',
          port: '{}',
          wifi: '{}',
          xvpn: '{}',
        },
        phoneKeys: {
          relationId: this.relationId,
          relationName: this.relationName,
          dynamicKey: '{}',
          extKey: '{}',
          programKey: '{}',
          dssKey: '{}',
        },
        provision: {
          relationId: this.relationId,
          relationName: this.relationName,
          autoProvision: '{}',
          edm: '{}',
          tr069: '{}',
        },
        settings: {
          relationId: this.relationId,
          relationName: this.relationName,
          audio: '{}',
          bluetooth: '{}',
          callDisplay: '{}',
          dialingRule: '{}',
          display: '{}',
          general: '{}',
          phoneLock: '{}',
          ringing: '{}',
          screen: '{}',
          timeDate: '{}',
          tones: '{}',
          usb: '{}',
        },
        sipFunction: {
          relationId: this.relationId,
          relationName: this.relationName,
          general: '{}',
        },
      }
      methods.parseData(this.parData, (pareItem, label, [dataKey , groupKey, itemKey]) => {
        if (pareItem.Push) {  // 设置勾选，需要保存
          console.log('parseData', dataKey, itemKey)
          switch (dataKey) {
            case 'account': {  // 账号
              let accountNum = +groupKey.split('account')[1],  // 当前account编号
                index = submitForm[dataKey].findIndex(submitItem => submitItem.no === accountNum)  // 查找提交数据中与编号相同的索引
              if (index != -1) {  // 没有找到相同编号
                submitForm[dataKey][index][itemKey] = JSON.stringify({
                  ...JSON.parse(submitForm[dataKey][index][itemKey]),
                  [label]: pareItem.Value  // 加入勾选设置
                })
              } else {  // 找到相同编号（没找到触发行为，未重写）
                let accountObj = { ...submitForm.account }
                accountObj.no = accountNum
                let itemObj = JSON.parse(accountObj[itemKey])
                itemObj[label] = pareItem.Value
                accountObj[itemKey] = JSON.stringify(itemObj)
                submitForm[dataKey].push(accountObj)
              }
              break
            }
            case 'phoneKeys':  // 话机编程键
              Object.values(pareItem).forEach((phoneItem) => {
                if (typeof phoneItem == 'object') {
                  submitForm[dataKey][groupKey] = JSON.stringify({
                    ...JSON.parse(submitForm[dataKey][groupKey]),
                    [phoneItem.Key]: phoneItem.Value
                  })
                }
              })
              break
            default:  // 非特殊类型
              submitForm[dataKey][groupKey] = JSON.stringify({
                ...JSON.parse(submitForm[dataKey][groupKey]),
                [label]: pareItem.Value
              })
              break
          }
        }
      })
      return submitForm
    },
    // 解析extent（提取key、value）
    parseExtent() {
      return (extent) => {
        let result
        if(typeof extent === 'string' || extent instanceof String){
          result = extent
        }else{
          result = extent.extent
        }
        return result
          .replace('<?xml version="1.0" encoding="UTF-8"?>\n<settings>', '')
          .replace('</settings>', '')
          .replace(/\n/g, '~')
          .replace(/\r/g, '`')
          .split(/[~`]/)
          .map(item => {
            let pattern = /id="([\S\d]*)" value="([\S\d]*)"/g
            let arr = pattern.exec(item)
            if (arr) return arr
          })
          .filter(item => !!item)
      }
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    switchShow() {
      this.textEditShow = !this.textEditShow
      !this.textEditShow && this.refresh()
    },
    // 切换
    textEditShowChange() {
      if (this.hasDataChange) {
        this.switchShow()
      } else {
        this.$confirm(this.$t('sipAccount.plzSaveText'), this.$t('tip'), {
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
        })
          .then(() => {
            if (this.textEditShow)
              this.extentToSetting()  // 根据extents修改setting
            else
              this.settingToExtent()  // 根据setting修改extents
            this.onlysave(true)       // 保存修改
          })
          .catch(() => {
            // 取消保存，恢复备份
            if (this.textEditShow)
              this.extent = JSON.parse(JSON.stringify(this.backupExtent))
            else
              this.parData = JSON.parse(JSON.stringify(this.backupData))
            this.switchShow()
          })
      }
    },
    // 设置总数
    changeTotal(val, tabActive) {
      this.$set(this.total, tabActive, val)
    },
    // 获取数据
    getData() {
      const requestData = {
        relationId: this.relationId,
        relationName: this.relationName
      }
      // 是否推送
      configPushRecord(requestData)
        .then(({ data }) => {
          const pushState = !data.result.rows[0].configPush
          // 获取参数
          getParam(requestData)
            .then(({ data }) => {
              let { extent, ...settingData } = data.result.rows[0]
              Object.entries(settingData).forEach(([dataKey, dataValue]) => {
                if (dataValue.length)
                  dataValue.forEach((item) => {
                    this.$set(this.total, dataKey, 0)
                    this.getDataSet({
                      pushState,
                      data: item,
                      label: dataKey
                    })
                  })
              })
              let model = this.$route.query.deviceModel
              if(extent[0] && extent[0] != null){
                this.extent = extent[0].extent
              }else{
                this.extent = ''
              }
              if (this.extent === '' && model != "H2" && model != "H2P") 
                this.extent = `<?xml version="1.0" encoding="UTF-8"?>\n<settings>\n\n\n</settings>`
              this.backupData = JSON.parse(JSON.stringify(this.parData))
              this.backupExtent = JSON.parse(JSON.stringify(this.extent))
            })
        })
    },
    // 数据插入
    getDataSet({ pushState, label, data  }) {
      Object.keys(data).forEach(key => {
        const excludeArr = ['id', 'relationId', 'relationName', 'no']
        if (this.relationName != 'mac') excludeArr.push('basic')
        if (data[key] && !excludeArr.includes(key)) {
          data[key] = JSON.parse(data[key])
          switch (label) {
            case 'account':  // 账号
              methods.setValue(
                data[key],
                this.parData[label][`account${data.no}`],
                pushState,
                () => ++this.total[label],
                'account'
              )
              break
            case 'phoneKeys':  // 话机编程键
              methods.setValue(
                data[key],
                this.parData[label][key],
                pushState,
                (ex, es) => es.Push && this.phoneKeyPushList.add(es.Account.Key),
                'phoneKeys'
              )
              this.total[label] = [...this.phoneKeyPushList].length
              break
            default:  // 非特殊类型
              methods.setValue(
                data[key],
                this.parData[label],
                pushState,
                (e, ee) => {
                  ++this.total[label]
                  if (!ee.label) ee.label = e[0]
                }
              )
              break
          }
        }
      })
    },
    // PhoneKeys格式化
    getPhoneKeys(data) {
      Object.entries(data).forEach((e) => {
        if (e[1].data) {
          // keys
          let keys = []
          if (typeof e[1].otherData.keys == 'number') {
            for (let i = 1; i < e[1].otherData.keys + 1; i++) {
              keys.push(i)
            }
          } else {
            keys = e[1].otherData.keys
          }
          // arr
          let arr = [...e[1].data]
          keys.forEach((ee) => {
            let template = JSON.parse(JSON.stringify(e[1].otherData.template))
            Object.entries(template).forEach((eee) => {
              eee[1].Key = eee[1].Key.replace('$', ee)
            })
            template.Push = false
            template.Name = ee
            arr.push(template)
          })
          data[e[0]] = arr
        } else {
          this.getPhoneKeys(e[1])
        }
      })
    },
    // 更新设置（extent -> setting）
    extentToSetting() {
      let extentObj = {}
      this.parseExtent(this.extent).forEach(item => extentObj[item[1]] = item[2])
      let extentKeys = Object.keys(extentObj)
      // 遍历设置，如果xml有设置勾选并设置，如果没有取消勾选并置为默认值
      methods.parseData(this.parData, (pareItem, label) => {
        if (typeof(label) === 'number') {  // 话机按键
          let phoneKeyPush = false
          Object.entries(pareItem).forEach(tableItem => {
            let phoneKeyItem = tableItem[1]
            if (Object.prototype.toString.call(phoneKeyItem) === '[object Object]') {
              let sameKey = extentKeys.find(extent => extent === phoneKeyItem.Key)
              if (sameKey) {
                phoneKeyItem.Value = extentObj[sameKey]
                phoneKeyPush = true
              } else {
                phoneKeyItem.Value = phoneKeyItem.DefaultValue
              }
            }
          })
          pareItem.Push = phoneKeyPush
        } else {
          let sameKey = extentKeys.find(extent => extent === label)
          if (sameKey) {
            pareItem.Value = extentObj[sameKey]
            pareItem.Push = true
          } else {
            pareItem.Value = pareItem.DefaultValue
            pareItem.Push = false
          }
        }
      })
    },
    // 更新xml（setting -> extent)
    settingToExtent() {
      let defaultKeys = [],  // 默认设置key
        settingList = [],    // 设置（页面可见）
        customExtent = []    // 自定义设置（xml增加，页面不可见）
      methods.parseData(this.parData, (pareItem, label) => {
        if (typeof(label) === 'number') {  // 话机按键
          Object.entries(pareItem).forEach(tableItem => {
            let { Key, Value } = tableItem[1]
            if (Object.prototype.toString.call(tableItem[1]) === '[object Object]') {
              defaultKeys.push(Key)
              pareItem.Push && settingList.push([Key, Value])
            }
          })
        } else {
          defaultKeys.push(label)
          pareItem.Push && settingList.push([label, pareItem.Value])
        }
      })
      this.parseExtent(this.extent).forEach(extentItem => {
        let sameKey = defaultKeys.find(defaultKey => defaultKey === extentItem[1])
        if (!sameKey) customExtent.push([extentItem[1], extentItem[2]])
      })
      let contentList = [
        ...settingList,
        ...customExtent
      ].map(([label, value]) => `<setting id="${label}" value="${value}" override="true"/>`)
      this.extent = `<?xml version="1.0" encoding="UTF-8"?>\n<settings>\n\r${contentList.join('\n\r')}\n\r</settings>`
    },
    // 统一数据
    unifyData() {
      if (this.textEditShow)
        this.extentToSetting()  // 根据extents修改setting
      else
        this.settingToExtent()  // 根据setting修改extents
    },
    // 保存
    onlysave(isSwitch) {
      this.unifyData()
      saveParam(this.submitData)
        .then((res) => {
          if (isSwitch) {
            this.backupData = JSON.parse(JSON.stringify(this.parData))
            this.getData()
            this.switchShow()
          } else {
            this.$message.success(res.data.message)
            this.$router.push({ name: this.relationName })
          }
        })
    },
    //取消
    cancel() {
      let route = this.$route.path
      console.log("route:",route, this.relationId, this.relationName)
      if (route === "/site/addSite" || route === "/group/addGroup" || route === "/model/addModel") {
        if (this.relationName === "model") {
          console.log("removeModel")
          removeModel(this.relationId)
        } else if (this.relationName === "group") {
          console.log("removeGroup")
          removeGroup(this.relationId)
        } else if (this.relationName === "site") {
          console.log("removeSite")
          removeSite(this.relationId)
        }

      }
    },
    // 保存并推送
    submit() {
      this.unifyData()
      saveParam(this.submitData).then((res) => {
        if (this.pushStatus != 0) {
          this.pushDevice()
        } else {
          this.$message.success(res.data.message)
          this.$router.push({ name: this.relationName })
        }
      })
    },
    // 推送
    pushDevice() {
      this.unifyData()
      if (this.relationName == 'device') {
        saveParam(this.submitData)
          .then(() => {
            pushDeviceConfig(this.relationId)
              .then(({ data }) => {
                this.$message.success(data.message)
                this.$router.push({ name: this.relationName })
              })
              .catch(() => {})
          })
      } else {
        this.pushDeviceVisible = true
      }
    },
    // 选择推送方式提交
    taskExecutionModeSubmit(params) {
      saveParam(this.submitData)
        .then(() => {
          const arr = ['model', 'group', 'site']
          if (arr.includes(this.relationName)) params.deviceType = 3
          params.taskParam = this.relationId
          const pushDeviceApi = {
            1: {
              model: pushConfigModel,
              group: pushConfigGroup,
              site: pushConfigSite,
            },
            2: {
              model: pushConfigModelSchedule,
              group: pushConfigGroupSchedule,
              site: pushConfigSiteSchedule,
            },
          }
          const pushDeviceApi2 = pushDeviceApi[params.type]
          pushDeviceApi2[this.relationName](params)
            .then(() => {
              pushSuccessMassage(this)
              this.$router.push({ name: this.relationName })
            })
            .catch(() => {})
        })
    },
    // 刷新
    refresh() {
      let data = JSON.parse(JSON.stringify(parameters));
      let route = this.$route.path
      this.phoneKeyPushList = new Set()
      this.getPhoneKeys(data.phoneKeys);
      if (route === "/site/setParam" || route === "/group/setParam" || route === "/site/addSite" || route === "/group/addGroup"
        || route === "/group/editGroup" || route === "/site/editSite") {
        //this.parData = data
        //this.getData()
        dynamicParameterJs({
          data: data,
          model: "share",
        }).then((res) => {
          this.parData = res.data.result.rows[0]
          this.getData()
       });
      } else {
        dynamicParameterJs({
          data: data,
          model: this.$route.query.deviceModel,
        }).then((res) => {
          this.parData = res.data.result.rows[0]
          this.getData()
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.steps {
  margin: 0 140px 70px;
}
.page-main {
  padding: 0;
}
.changeText {
  float: right;
}
.el-tabs {
  height: 100%;
  background-color: #FFF;
  ::v-deep .el-tabs__header {
    height: 72px;
    margin-bottom: 0;
    .el-tabs__nav-wrap {
      height: 100%;
      padding: 0 20px;
      .el-tabs__nav-scroll {
        display: flex;
        align-items: center;
        height: 100%;
        .el-tabs__item {
          font-size: 20px;
          color: #666;
          &:hover {
            color: #333;
          }
          &:active {
            color: #333;
          }
        }
        .el-tabs__item.is-active {
          color: #333;
        }
      }
    }
  }
  ::v-deep .el-tabs__content {
    height: calc(100% - 72px);
  }
  .el-tab-pane {
    height: 100%;
  }
  ::v-deep .el-badge__content.is-fixed {
    top: 0;
    right: 9px;
  }
}
</style>

<style lang="scss">
.param-tab {
  display: flex;
  height: 100%;
  background-color: white;
  .aside {
    box-sizing: border-box;
    vertical-align: top;
    padding: 20px 0;
    width: 230px;
    border-right: 1px solid #e6ebf1;
    overflow-y: auto;
    .select-account {
      margin: 0 20px 20px 20px;
      width: calc(100% - 40px);
    }
    .menu {
      > div {
        cursor: pointer;
        padding: 0 20px;
        height: 56px;
        line-height: 56px;
        > span {
          float: right;
          margin-top: calc((56px - 20px) / 2);
          margin-right: -10px;
          padding: 0 6px;
          height: 20px;
          font-size: 0.75em;
          color: white;
          text-align: center;
          background-color: rebeccapurple;
          line-height: 20px;
          border-radius: 10px;
        }
      }
    }
    .active {
      background-color: #F6F6F6;
      color: $--color-primary;
    }
  }
  .main {
    vertical-align: top;
    position: relative;
    width: calc(100% - 200px);
    padding: 20px 20px 65px 20px;
    .main-header {
      margin-bottom: 20px;
    }
    .main-body {
      height: calc(100% - 65px);
      overflow-y: auto;
      position: relative;
      .checkbox {
        position: absolute;
        left: 0;
      }
    }
    .main-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      padding: 10px;
      width: 100%;
      text-align: center;
      border-top: 1px solid #e6ebf1;
    }
    ::v-deep.el-form-item__label {
      padding-left: 20px;
    }
  }
}
</style>
