import dayjs from 'dayjs'
import store from '@/store'

// 日期格式化
function dateFormat(value, format = 'YYYY/MM/DD HH:mm', isZoneOffset = false) {
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc)
  console.log("dateFormat isZoneOffset:%s offset account:", isZoneOffset ? "true":"false", store.state.account.zoneOffset)
  
  if (store.state.zoneId != null) {
    const zoneOffset = isZoneOffset ? ((store.state.account.zoneOffset) * 60000) : 0 //CRTXEDS-935, XSH
    console.log(" currentZone:%d offset:%d", 
      dayjs().utcOffset(), zoneOffset, value, store.state.loginInfo) 
  }
  //if (store.state.account.zoneOffset != 0)
  {
    console.log("value:%d account.zoneOffset:%d utcOffset:%d",value, store.state.account.zoneOffset,   dayjs().utcOffset())
    value = value + (store.state.account.zoneOffset - dayjs().utcOffset()) * 60000
    return dayjs(value).format(format)
  }
  /*else  
    return dayjs(value).format(format)*/
}

function nowTime() {
  return new Date().getTime()
}

// 时常格式化
function durationFormat(value) {
  var theTime = parseInt(value) // 秒
  var middle = 0 // 分
  var hour = 0 // 小时
  if (theTime > 60) {
    middle = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (middle > 60) {
      hour = parseInt(middle / 60)
      middle = parseInt(middle % 60)
    }
  }
  var result = '' + parseInt(theTime) + 's'
  if (middle > 0) {
    result = '' + parseInt(middle) + 'm' + result
  }
  if (hour > 0) {
    result = '' + parseInt(hour) + 'h' + result
  }
  return result
}

let DateFilter = {}

DateFilter.install = function(Vue) {
  Vue.prototype.$dateFormat = dateFormat // 日期格式化
  Vue.filter('dateFormat', dateFormat) // 日期格式化
  Vue.prototype.$nowTime = nowTime // 获取当前时间戳
  Vue.filter('urationFormat', durationFormat) // 时常格式化
}

export default DateFilter
